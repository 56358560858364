import React, { forwardRef, memo, useImperativeHandle, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DialogComponent } from 'components/ui-kit/Dialog/index'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useNotSigninStyles } from './style'
import { colors } from 'theme/colors/index'
import Button from 'components/Button/index'
import { BrandLogoIcon } from 'components/ui-kit/icons/iconComponents/BrandLogoIcon'
import CloseIcon from '@mui/icons-material/Close';
import { getAuthToken } from 'helpers/cookie.helpers'

const NotSigninDialog = forwardRef((props, ref) => {
  const navigate = useNavigate()
  const classes = useNotSigninStyles()
  const [dialogOpen, setDialogOpen] = useState();
  const [redirectTo, setRedirectTo] = useState();

  const { t } = useTranslation();

  const closeModal = () => {
    setDialogOpen(false);
  }

  const handleDialogClose = () => {
    navigate(ROUTE_PATHS?.register, { state: { redirectTo } })
    closeModal();
  }

  const handleDialogLogin = () => {
    navigate(ROUTE_PATHS?.login, { state: { redirectTo } })
    closeModal();
  }

  const validate = (callback = () => null, error = () => null, redirectTo = null) => {
    if (!getAuthToken()) {
      setDialogOpen(true);
      setRedirectTo(redirectTo)
      if (error) error("User is not logged in");
    } else if (callback) callback();
  }

  useImperativeHandle(ref, () => ({ validate }));

  return (
    <DialogComponent dialogOpen={dialogOpen}>
      <Grid className={classes.container} container direction='column' alignContent='center'>
        <Button variant='primary' icon className={`${classes.btnClose}`} onClick={closeModal}>
          <CloseIcon />
        </Button>
        <Grid className={classes.header}>
          <Typography variant={"h5"}>{t("welcomeTo")}</Typography>
          <BrandLogoIcon />
        </Grid>
        <Grid item xs={6}>
          <Typography align='center'>
            {t('weNoticedThatYouAreNotYetConnectedToOurPlatform')}
          </Typography>
          <Typography align='center'>
            {t('toAccessChooseOneOfTheOptionsBelow')}
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' pt={1.5} pb={1.5}>
          <Grid item xs={12} mb={1}>
            <Button onClick={handleDialogClose} className={classes.btn}>{t('iWantToRegister')}</Button>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleDialogLogin} className={classes.btn}>{t('iAmAuser')}</Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography align='center'>
            {t('needHelp')} <a style={{ cursor: "pointer" }} onClick={() => window.Tawk_API.maximize()}>{t('clickHere')}</a>
          </Typography>
        </Grid>
      </Grid>
    </DialogComponent>
  )
})

export default memo(NotSigninDialog)
