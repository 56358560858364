import { darken, lighten, alpha } from '@mui/material/styles';

const palette = {
  primary: "#005d80",
  secondary: "#49c7f4",
  error: "#dc3545",
  warning: "#fd9843",
  info: "#3d8bfd",
  success: "#22C55E",

}

export const colors = {
  ...palette,

  //  main color
  white: "#FFFFFF",
  textColor: "#E5E5E5",
  btnPrimary: "#005d80",
  btnPrimaryHover: "#005d80",
  btnSecondary: "#49c7f4",
  btnSecondaryHover: "#005d80",

  primaryLightBlue: "#006d96",
  primaryDarkBlue: "#003144",
  primaryDarkBlue400: "#002736",
  bodyBg: "#006d96",
  // other color

  darkPurpleBlue: "#0F1229",

  black: "#000",
  red: "#FF0000",
  primaryLightBlueTransparent: "rgb(0 109 150 / 42%)",
  themeCard: "#004B67",
  noResult: "#EFC11B",
  themeBorder: "#3D829B",
  footerBg: "#004864",
  copyRightText: "#82C2CE",
  overlayBg: "rgb(0 49 68 / 63%)",
  greyShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  winnersCardText: "#91B8C2",
  tableTextColor: "#A4A4A6",
  placeHolderColor: "#B1BCC3",
  tableOddBg: "#0A3B4E",
  tableEvenBg: "#003144",
  UserSectionColor: "#C8D2F0",
  neutralLightBlue: "#F1F5F9",
  neonBlue: "rgba(199, 240, 214, 0.5)",
  darkYellow: "#F59E0B",
  pink: "#EF4444",
  darkRed: "#B40000",
  lightRed: "#EB342C",
  forestGreen: "#22C55E",
  activeGreen: "#4BC500",
  blue: "#3B82F6",
  lightPurple: "#728CF8",
  purple: "#4F46E5",
  grey300: "#e8e8e8",
  grey400: "#CECECE",
  grey500: "#888",
  gunmetalBlue: "#2C3344",
  darkNavyBlue: "#0F172A",
  navyBlue: "#317ACF",
  homeColor: "#0D3B4D",
  sliderReview: "#F5F5F5",
  sliderDots: "#056F98",
  searchAccordain: "#042b32",
  accordionBg: "#00314470",
  loyaltyProgress: "#00314480",
  rejectedRed: "#f50057",
  discoverAllBg: '#00000073',
  iCardBg: "#004B67",
  iCardOddChildBg: "#000000ab",
  errorRed: "#d50000",
  iFramBg: "#004965",

  // others
  dangerTextColor: "rgb(241 65 108 / 1)",
  dangerBackgroundColor: "#f1416c38",

  overlay: 'rgb(0 0 0 / 64%)',
};
