import { createAsyncThunk } from "@reduxjs/toolkit";

import { removeAuthToken, removeVisitSessionCookie, signIn, signOut } from "helpers/cookie.helpers";
import {
  loginService,
  userInformationService,
  userSignUpService,
  forgotPasswordService,
  resetPasswordService,
  createAffiliateService,
  depositAmountService,
  withdrawAmountService,
  getAllCurrencies,
  getUserWithdrawRequests,
  validateUserReCaptchaService,
  updateUserProfileService,
  resetUserPasswordService,
  verifyRegistrationEmailToken,
  claimCoinsService,
  fetchKYCDocumentLabelService,
  uploadKYCDocumentLabelService,
  fetchUploadedKYCDocumentsLabelService,
  getSportsbookTokenService,
  updateLanguageService,
  logoutService,
  userFirstVisit,
} from "network/services/user.services";
// import i18n from 'i18next'
import { ROUTE_PATHS } from "constants/index";
import { openErrorToaster, openSuccessToaster } from "helpers/toaster.helpers";
import {
  handleRegistrationCompleteDialog,
  setSportsbookToken,
} from "redux-thunk/redux/user/user.slice";
import { encodePassword } from "utils/commonFunctions";
import { removeBetbyToken, setBetbyToken } from "helpers/localstorage.helpers";
import { OnlybetsPluginRef } from "helpers/player.helpers";
// import { handleLanguageChange } from 'redux-thunk/redux/user/user.slice'

/**
 * Fetch User Information Thunk
 */
export const fetchUserInformationThunk = createAsyncThunk(
  "fetch/userInfo",
  async (thunkApi) => {
    try {
      const res = await userInformationService();
      if (window['OnlybetsPluginRef']) {
        window['OnlybetsPluginRef'].setUser(res.getUser);
      }
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Login Thunk
 */
export const userLoginThunk = createAsyncThunk(
  "user/login",
  async ({ userName, password, isMobile, pathname, navigate, location }, thunkApi) => {
    try {
      const res = await loginService({
        email: userName,
        password: btoa(password),
        isMobile
      });
      const { accessToken, ...userData } = res;
      signIn({ token: accessToken });
      if (location?.state?.redirectTo) {
        navigate(location.state.redirectTo, { replace: true, state: { redirectTo: null } });
      } else if (pathname) {
        navigate(pathname, { replace: true });
      } else {
        navigate(-1, { replace: true });
      }

      OnlybetsPluginRef().reload({ demoVideoSrc: false });

      return userData;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Logout Thunk
 */
export const userLogoutThunk = createAsyncThunk(
  "user/logout",
  async (thunkApi) => {
    try {
      const res = await logoutService();
      OnlybetsPluginRef().reload();
      return { message: "Logout", res };
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Signup Thunk
 */
export const signUpNewUser = createAsyncThunk(
  "user/sign-up",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await userSignUpService(payload);
      removeVisitSessionCookie()
      thunkApi.dispatch(handleRegistrationCompleteDialog(true));
      // navigate(ROUTE_PATHS.login, { replace: true })
      return { message: "Sign-Up Successful", res };
    } catch (error) {
      //openErrorToaster(thunkApi.dispatch, error[0].description);
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Forgot Password Thunk
 */
export const forgotPasswordThunk = createAsyncThunk(
  "user/forgot-password",
  async ({ payload }, thunkApi) => {
    try {
      const res = await forgotPasswordService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Reset Password Thunk
 */
export const resetPasswordThunk = createAsyncThunk(
  "user/reset-password",
  async ({ payload }, thunkApi) => {
    try {
      const res = await resetPasswordService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]);
    }
  }
);

/**
 * Create Affiliate Thunk
 */
export const createAffiliateThunk = createAsyncThunk(
  "affiliate/create-affiliate",
  async ({ payload }, thunkApi) => {
    try {
      const res = await createAffiliateService(payload);
      openSuccessToaster(
        thunkApi.dispatch,
        "Cadastro enviado para aprovação, em breve entraremos em contato."
      );
      return { message: "Successfully registered", res };
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Deposit Thunk
 */

export const depositAmountThunk = createAsyncThunk(
  "user/deposit",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await depositAmountService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Withdrawal Thunk
 */

export const withdrawalAmountThunk = createAsyncThunk(
  "user/withdraw",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await withdrawAmountService(payload);
      openSuccessToaster(thunkApi.dispatch, "Withdraw Requests Generated");
      thunkApi.dispatch(fetchUserWithdrawRequestsThunk({}));
      return { message: "Withdraw requested", res };
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Deposit Thunk
 */

export const getAvailableCurrencies = createAsyncThunk(
  "user/currencies",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await getAllCurrencies();
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * User Withdraw Requests Thunk
 */

export const fetchUserWithdrawRequestsThunk = createAsyncThunk(
  "user/withdrawRequests",
  async ({ payload, navigate }, thunkApi) => {
    try {
      const res = await getUserWithdrawRequests();
      return res?.withdrawRequests?.rows || [];
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Validate User ReCaptcha Token
 */

export const validateUserReCaptchaToken = createAsyncThunk(
  "user/validateRecaptcha",
  async ({ token }, thunkApi) => {
    try {
      const res = await validateUserReCaptchaService({ token });
      return res.tokenStatus || false;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Update User Profile
 */

export const updateUserProfile = createAsyncThunk(
  "user/updateprofile",
  async (payload, thunkApi) => {
    try {
      const res = await updateUserProfileService(payload);
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Get Sportsbook User Token
 */

export const getSportsbookToken = createAsyncThunk(
  "user/getSportsbookToken",
  async (payload, thunkApi) => {
    try {
      const res = await getSportsbookTokenService();
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/**
 * Reset User Password
 */
export const updateUserProfilePasswordThunk = createAsyncThunk(
  "userProfile/update-password",
  async (payload, thunkApi) => {
    try {
      const res = await resetUserPasswordService(payload);
      // openSuccessToaster(thunkApi.dispatch, 'Password Reset Successfully.')
      // removeAuthToken()
      // navigate(ROUTE_PATHS.login)
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

/** Verify Email Token */

export const verifyRegistrationEmailTokenThunk = createAsyncThunk(
  "user/verify-email",
  async ({ emailToken, navigate }, thunkApi) => {
    try {
      const res = await verifyRegistrationEmailToken({ emailToken });
      openSuccessToaster(thunkApi.dispatch, "Email Verified Successfully.");
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    } finally {
      navigate(ROUTE_PATHS.login);
    }
  }
);

export const claimCoinsThunk = createAsyncThunk(
  "user/verify-email",
  async (payload, thunkApi) => {
    try {
      const res = await claimCoinsService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const fetchDocumentLabelThunk = createAsyncThunk(
  "kyc/get-document-label",
  async (payload, thunkApi) => {
    try {
      const res = await fetchKYCDocumentLabelService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const uploadKYCDocumentLabelThunk = createAsyncThunk(
  "kyc/update-user-document",
  async (payload, thunkApi) => {
    try {
      const res = await uploadKYCDocumentLabelService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
export const fetchUploadedKYCDocumentsLabelThunk = createAsyncThunk(
  "kyc/document/get-documents",
  async (payload, thunkApi) => {
    try {
      const res = await fetchUploadedKYCDocumentsLabelService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);

export const updateLanguageThunk = createAsyncThunk(
  "update/language",
  async (payload, thunkApi) => {
    try {
      const result = await updateLanguageService(payload);
      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description);
    }
  }
);
