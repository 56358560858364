import config from 'config/app.config'
import { signOut } from 'helpers/cookie.helpers'
import { openErrorToaster } from 'helpers/toaster.helpers'
import { getTranslatedErrorMessage, getTranslation } from 'helpers/translations.helpers'
import store from 'index'
import errorMessages from 'network/messages/errorMessages'
import { stopLoader } from 'redux-thunk/redux/loader/loader.slice'

export const errorHandler = (error) => {
  if (!error?.config?.showApiErrorMessage) {
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  }
  if (error.response.status === 401) {
    const errorDesc = error?.response?.data?.errors[0]?.name || errorMessages.unAuthorized
    openErrorToaster(store.dispatch, getTranslation(errorDesc))
    signOut()
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 500) {
    // Snackbar Internal Server Error
    openErrorToaster(store.dispatch, getTranslation(errorMessages.internalServerError))
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 403) {
    const errorDesc = error?.response?.data?.errors[0]?.name || errorMessages.unAuthorized
    openErrorToaster(store.dispatch, getTranslation(errorDesc))
    store.dispatch(stopLoader(error.response.config.loader))
    signOut()
    window.location.href = config?.BASE_URL  // TODO: FIX THIS
    return Promise.reject(error.response.data.errors)
  }
  // Other errors
  if (error.response.config.loader) {
    store.dispatch(stopLoader(error.response.config.loader))
  }
  // Open Error Toaster

  const errorDesc = error?.response?.data?.errors[0]?.name || errorMessages.unknownError
  openErrorToaster(store.dispatch, getTranslation(errorDesc))
  // const apiErrorCode = error.response.data.errors[0].errorCode
  // openErrorToaster(store.dispatch, getTranslatedErrorMessage(apiErrorCode))
  return Promise.reject(error.response.data.errors)
}
